import { _t } from "@/tools/Utils";
import { lessons_array } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import { levels } from "@/packages/admin/schema/LEVELS";

const { required, decimal } = require("vuelidate/lib/validators");

export const ADD_LESSON_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "name",
      label: "lesson_name",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "title",
      label: "lesson_title",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "subtitle",
      label: "lesson_subtitle",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "level",
      label: "level",
      placeholder: "",
      show: true,
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "select",
      items: [...levels],
    },

    {
      id: "teachingTimeInHour",
      label: "teaching_time",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "baseType",
      label: "baseType",
      placeholder: "",
      show: true,
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      value: "value",
      text: "name",
      type: "select",
      items: [{ name: _t("attribute.any"), value: "any" }, ...lessons_array],
    },
    {
      id: "description",
      label: "description",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    name: null,
    title: null,
    subtitle: null,
    level: null,
    baseType: null,
    teachingTimeInHour: null,
    description: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      name: {
        required,
      },
      baseType: { required },
      title: { required },
      subtitle: { required },
      level: { required },
      teachingTimeInHour: { required, decimal },
      description: {},
    },
  },
};
