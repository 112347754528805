<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="user.role !== 'Teacher'">
        <UsersTable />
      </v-col>
      <v-col cols="12" v-if="user.role !== 'Teacher'">
        <LessonsTable />
      </v-col>
      <v-col cols="12" v-if="user.role !== 'Teacher'">
        <SpecificationsTable />
      </v-col>
<!--      <v-col cols="12">-->
<!--        <TasksTable />-->
<!--      </v-col>-->
    </v-row>
  </div>
</template>

<script>
import UsersTable from "@/packages/admin/components/users/UsersTable";
import LessonsTable from "@/packages/admin/components/lessons/LessonsTable";
import SpecificationsTable from "@/packages/admin/components/specifications/SpecificationsTable";
import { mapGetters } from "vuex";
export default {
  name: "dashboard",
  components: { SpecificationsTable, LessonsTable, UsersTable },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
};
</script>

<style scoped></style>
