import { _t } from "@/tools/Utils";
import { roles } from "@/packages/admin/schema/ROLES";

export const USER_TABLE_SCHEMA = {
  headers: [
    {
      text: _t("admin.components.user_table.header.name"),
      align: "start",
      sortable: false,
      value: "fullName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("admin.components.user_table.header.nickname"),
      sortable: false,
      value: "nickName",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("admin.components.user_table.header.username"),
      sortable: false,
      value: "userName",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("admin.components.user_table.header.role"),
      sortable: false,
      value: "role",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.baseType"),
      sortable: false,
      value: "baseType",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.state"),
      sortable: false,
      value: "state",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: "",
      sortable: false,
      value: "actions",
      class: "text--disabled text-subtitle-1",
      width: "200px",
    },
    {
      text: "",
      sortable: false,
      value: "archive",
      class: "text--disabled text-subtitle-1",
    },
  ],
  filters: [{ text: _t("attribute.all"), value: null }, ...roles],
};
