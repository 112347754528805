import { _t } from "@/tools/Utils";

export const roles = [
  // { text: _t("attribute.Admin"), value: "Admin" },
  { text: _t("attribute.Teacher"), value: "Teacher" },
  { text: _t("attribute.Student"), value: "Student" },
  { text: _t("attribute.FinancialAdmin"), value: "FinancialAdmin" },
  { text: _t("attribute.SchedulingAdmin"), value: "SchedulingAdmin" },
  { text: _t("attribute.TeamLeader"), value: "TeamLeader" },
  { text: _t("attribute.AcademicConsultant"), value: "AcademicConsultant" },
  { text: _t("attribute.AssistantTeacher"), value: "AssistantTeacher" },
];

export const teamLeader_roles = [
  { text: _t("attribute.Teacher"), value: "Teacher" },
];

export const academic_roles = [
  { text: _t("attribute.Student"), value: "Student" },
];
