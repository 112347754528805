<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :title="$_t('admin.components.specification_table.title')" />
      </v-col>
      <v-spacer />
      <v-col
        cols="6"
        sm="4"
        md="2"
        v-if="['Admin', 'TeamLeader', 'AcademicConsultant'].includes(authRole)"
      >
        <v-btn
          width="11rem"
          height="2.5rem"
          @click="showModal"
          color="primary"
          dark
          class="float-end"
        >
          <v-icon class="mr-3">mdi-book-outline</v-icon>
          {{ $_t("admin.components.admin_actions.add_specification") }}
        </v-btn>
        <!--        modal -->
        <v-dialog
          width="1000px"
          v-model="isShowModal"
          transition="dialog-bottom-transition"
        >
          <v-card class="px-4">
            <v-toolbar class="elevation-0" color="transparent">
              <span class="text-h5 font-weight-bold">{{
                specification
                  ? $_t(
                      "admin.components.specification_table.edit_specification"
                    )
                  : $_t(
                      "admin.components.specification_table.add_specification"
                    )
              }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <MainSoftForm>
              <template v-slot:body>
                <MyFormGenerator
                  lg="10"
                  :schema="schema"
                  :validator="$v"
                  :enter="submit"
                ></MyFormGenerator>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-select
                        outlined
                        :label="$_t('attribute.add_lesson_to_course')"
                        multiple
                        dense
                        :item-text="(item) => `${item.name} | ${item.subTitle}`"
                        item-value="lessonId"
                        return-object
                        hide-details
                        v-model="lessonsSelect"
                        :items="lessonsList"
                      >
                        <template v-slot:selection="{ index, item }">
                          <v-chip color="primary" v-if="index < 4" class="my-2">
                            <span>{{
                              truncateString(
                                `${item.name} | ${item.subTitle}`,
                                12
                              )
                            }}</span>
                          </v-chip>
                          <span
                            v-if="index === 4"
                            class="grey--text text-caption"
                          >
                            (+{{ lessonsSelect.length - 4 }} others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <div class="px-3 py-2">
                            <v-text-field
                              outlined
                              :label="$_t('attribute.search')"
                              dense
                              hide-details
                              @input="searchInLessons"
                              v-model="lessonSearch"
                            />
                          </div>
                        </template>
                      </v-select>
                    </v-col>
                    <div style="width: 100%; padding: 10px">
                      <v-col cols="12" class="bordering">
                        <v-row class="blue lighten-4">
                          <v-col cols="5" class="pl-3 blue--text">{{
                            $_t("attribute.lessonName")
                          }}</v-col>
                          <v-col cols="3" class="pl-3 blue--text">{{
                            $_t("attribute.teachHour")
                          }}</v-col>
                          <v-col cols="4" class="pl-3 blue--text">{{
                            $_t("attribute.actions")
                          }}</v-col>
                        </v-row>
                        <Container
                          @drop="onDrop"
                          drag-handle-selector=".column-drag-handle"
                          lock-axis="y"
                        >
                          <Draggable
                            v-for="(item, index) in lessonsSelect"
                            :key="item.lessonId"
                            class="row d-flex border-bottom"
                          >
                            <v-col cols="5" class="d-flex align-center pl-0">
                              <div class="ml-3">
                                {{ index + 1 }}
                              </div>
                              <div class="d-flex flex-column ml-5">
                                <div class="font-weight-bold">
                                  {{ item.name }}
                                  <!--                                  {{-->
                                  <!--                                    item.description-->
                                  <!--                                      ? `| ${item.description}`-->
                                  <!--                                      : null-->
                                  <!--                                  }}-->
                                  {{
                                    item.description
                                      ? `| ${truncateString(
                                          item.description,
                                          20
                                        )}`
                                      : null
                                  }}
                                </div>
                                <div class="text--disabled">
                                  {{ item.title }} | {{ item.subTitle }} |
                                  {{ $_t(`attribute.${item.level}`) }}
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="3"
                              class="d-flex justify-center align-center"
                            >
                              <div class="d-flex justify-center align-baseline">
                                <v-text-field
                                  type="number"
                                  style="width: 100px"
                                  dense
                                  solo
                                  :label="$_t('attribute.hours')"
                                  :value="item.teachingTimeInHour"
                                  @input="
                                    bindTeachingTime({
                                      ...item,
                                      teachingTimeInHour: parseFloat($event),
                                    })
                                  "
                                />
                                <!--                                <v-chip outlined>-->
                                <!--                                  {{ `${item.teachingTimeInHour} hours` }}-->
                                <!--                                </v-chip>-->
                              </div>
                            </v-col>
                            <v-col cols="4">
                              <div class="d-flex justify-center align-baseline">
                                <span>
                                  <v-btn @click="deleteLesson(index)" text fab>
                                    <v-icon color="red"
                                      >mdi-trash-can-outline</v-icon
                                    >
                                  </v-btn>
                                </span>
                                <span class="column-drag-handle text--disabled">
                                  <v-icon>mdi-menu</v-icon>
                                </span>
                              </div>
                            </v-col>
                          </Draggable>
                        </Container>
                      </v-col>
                    </div>
                  </v-row>
                </v-col>
              </template>
              <template v-slot:action>
                <v-col cols="12">
                  <div class="d-flex justify-end">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">
                      {{ $_t("attribute.cancel") }}
                    </v-btn>

                    <v-btn
                      color="primary"
                      class="ml-4"
                      @click="submit"
                      :loading="is_loading"
                      :disabled="is_loading || $v.$error"
                    >
                      {{
                        specification
                          ? $_t("attribute.edit_course")
                          : $_t("attribute.add_course")
                      }}
                    </v-btn>
                  </div>
                </v-col>
              </template>
              <!--End   form actions ------------------------------------------------------------------>
            </MainSoftForm>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          flat
          outlined
          :label="$_t('attribute.level')"
          class="elevation-0"
          dense
          background-color="inputColor"
          :items="filters"
          @change="initsSpecifications({})"
          v-model="specificationType"
          item-text="text"
          item-value="value"
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          flat
          outlined
          :label="$_t('attribute.baseType')"
          class="elevation-0"
          dense
          background-color="inputColor"
          :items="baseTypeFilter"
          @change="initsSpecifications({})"
          v-model="baseType"
          item-text="name"
          item-value="value"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="
            pageInfo.page = 1;
            initsSpecifications({ search: $event });
          "
          :headers="headers"
          :items="specifications"
          :page-info="pageInfo"
          @detail="showDetail"
          @delete="deleteItem"
          :loading="is_loading"
        >
          <template v-slot:lessons="{ item }">
            <span
              v-for="(lesson, index) in item.lessons"
              :key="lesson.lessonId"
            >
              <v-chip color="primary" v-if="index < 4" class="my-2 mx-1">
                <span>{{
                  truncateString(`${lesson.name} | ${lesson.subTitle}`, 12)
                }}</span>
              </v-chip>
              <span v-if="index === 4" class="grey--text text-caption">
                (+{{ item.lessons.length - 4 }} others)
              </span>
            </span>
          </template>
          <template
            v-if="['Admin', 'TeamLeader'].includes(authRole)"
            #file="{ item }"
          >
            <CourseFiles @update="initsSpecifications({})" :course="item">
              <template #activator>
                <v-btn color="primary">{{ $_t("attribute.files") }}</v-btn>
              </template>
            </CourseFiles>
          </template>
          <template v-slot:level="{ item }">
            {{ $_t(`attribute.${item.level}`) }}
          </template>
          <template v-slot:baseType="{ item }">
            {{ $_t(`attribute.${item.baseType}`) }}
          </template>
          <template v-slot:actions></template>
          <template v-slot:pagination>
            <v-pagination
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="initsSpecifications"
            ></v-pagination>
          </template>
        </MainTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { reactive, ref } from "vue";
import { SPECIFICATIONS_TABLE_SCHEMA } from "@/packages/admin/schema/SPECIFICATIONS_TABLE_SCHEMA";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { ADD_SPEC_SCHEMA } from "@/packages/admin/schema/form/ADD_SPEC_SCHEMA";
import { Container, Draggable } from "vue-dndrop";
import { applyDrag } from "@/packages/admin/tools/helpers";
import message from "@/tools/Message";
import { lessons_filter } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import CourseFiles from "@/packages/admin/components/specifications/CourseFiles";

export default {
  name: "SpecificationsTable",
  components: {
    CourseFiles,
    MyFormGenerator,
    MainSoftForm,
    MainTable,
    TopTitle,
    Draggable,
    Container,
  },
  computed: {
    ...mapGetters({
      specifications: "admin/get_specifications",
      is_loading: "loading/is_loading",
      authRole: "authentication/role",
      lessonsList: "admin/get_lessons",
    }),
  },
  data() {
    return {
      schema: ADD_SPEC_SCHEMA.schema,
      form: ADD_SPEC_SCHEMA.model,
      lessonsSelect: [],
      specification: null,
      search: "",
      lessonSearch: "",
    };
  },
  validations: {
    ...ADD_SPEC_SCHEMA.validations,
  },
  setup(props) {
    const specificationType = ref(null);
    const baseType = ref(null);
    const baseTypeFilter = lessons_filter;
    const isShowModal = ref(false);
    const headers = SPECIFICATIONS_TABLE_SCHEMA.headers;
    const filters = SPECIFICATIONS_TABLE_SCHEMA.filters;
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: props.itemsPerPage || 10,
    });
    return {
      specificationType,
      baseTypeFilter,
      baseType,
      headers,
      pageInfo,
      filters,
      isShowModal,
    };
  },
  watch: {
    isShowModal(newVal) {
      if (!newVal) this.clear();
    },
    async "form.baseType"(val) {
      if (val) await this.searchInLessons();
    },
  },
  methods: {
    clear() {
      this.specification = null;
      this.lessonsSelect = [];
      this.form.downloadLink = null;
      this.form.level = null;
      this.form.title = null;
      this.form.baseType = null;
      this.lessonSearch = "";
      this.$v.$reset();
    },
    onDrop(dropResult) {
      this.lessonsSelect = applyDrag(this.lessonsSelect, dropResult);
    },
    bindTeachingTime(lesson) {
      this.lessonsSelect = this.lessonsSelect.map((l) =>
        l.lessonId === lesson.lessonId ? lesson : l
      );
    },
    async submit() {
      if (!this.isFormValidate()) return;
      if (!this.lessonsSelect.length)
        return message.error(this.$_t("SELECT_LESSONS"));
      let lessonInfo = this.lessonsSelect.map((item) => {
        return {
          lessonId: item.lessonId,
          teachingTimeInHour: item.teachingTimeInHour,
        };
      });
      let res = null;
      if (this.specification) {
        //edit
        res = await this.$actions.editSpecification({
          specificationId: this.specification.specificationId,
          title: this.form.title,
          level: this.form.level,
          downloadLink: this.form.downloadLink,
          baseType: this.form.baseType,
          lessonInfo,
        });
      } else {
        //create
        res = await this.$actions.createSpecification({
          title: this.form.title,
          level: this.form.level,
          downloadLink: this.form.downloadLink,
          baseType: this.form.baseType,
          lessonInfo,
        });
      }

      if (res) {
        await this.initsSpecifications({});
        this.close();
      }
    },
    showModal() {
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
      this.clear();
      this.$v.$reset();
    },
    async searchInLessons() {
      await this.$actions.getLessons({
        search: this.lessonSearch,
        all: true,
        baseType: this.form.baseType,
        dispatch: "admin/setLessons",
      });
    },
    deleteLesson(index) {
      this.lessonsSelect.splice(index, 1);
    },
    async initsSpecifications({ search = this.search }) {
      this.search = search;
      const { pageCount, specifications } =
        await this.$actions.getSpecifications({
          page: this.pageInfo.page,
          limit: this.pageInfo.itemsPerPage,
          level: this.specificationType,
          search: this.search,
          baseType: this.baseType,
        });
      await this.$store.dispatch("admin/setSpecifications", specifications);
      this.pageInfo.pageCount = pageCount;
    },
    showDetail(item) {
      this.specification = item;
      this.form.baseType = item.baseType;
      this.form.level = item.level;
      this.form.title = item.title;
      this.lessonsSelect = item.lessons.map((l) => {
        let lesson = { ...l };
        delete lesson.id;
        return lesson;
      });
      this.form.downloadLink = item.downloadLink;
      this.showModal();
    },
    async deleteItem(item) {
      await this.$actions.deleteSpecification(item.specificationId);
      await this.initsSpecifications({});
    },
  },
  created() {
    this.initsSpecifications({});
  },
};
</script>

<style scoped>
.bordering {
  height: 40vh;
  overflow-y: auto;
  border: 1px solid #e0e2e7;
  border-radius: 12px;
}
.column-drag-handle {
  float: right;
  padding: 0 10px;
  cursor: pointer;
}
.border-bottom {
  border-bottom: 1px solid #e0e2e7;
}
</style>
