import { levels } from "@/packages/admin/schema/LEVELS";
import { _t } from "@/tools/Utils";
import { lessons_array } from "@/packages/admin/schema/calender/LESSONS_ARRAY";

const { required, url } = require("vuelidate/lib/validators");

export const ADD_SPEC_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "title",
      label: "spec_title",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "level",
      label: "level",
      placeholder: "",
      show: true,
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "select",
      value: "value",
      text: "text",
      items: [...levels],
    },
    {
      id: "baseType",
      label: "baseType",
      placeholder: "",
      show: true,
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      value: "value",
      text: "name",
      type: "select",
      items: [{ name: _t("attribute.any"), value: "any" }, ...lessons_array],
    },
    {
      id: "downloadLink",
      label: "spec_download_link",
      hint: "Must start with http:// or https://",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
  ],

  /* Form MODEL */
  model: {
    title: null,
    baseType: null,
    level: null,
    downloadLink: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      title: { required },
      baseType: { required },
      level: { required },
      downloadLink: { url },
    },
  },
};
