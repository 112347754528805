import { roles } from "@/packages/admin/schema/ROLES";
import { _t } from "@/tools/Utils";

export const ADD_USER_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "role",
      label: "role",
      placeholder: "",
      show: true,
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      value: "value",
      type: "select",
      items: [...roles],
    },
    {
      id: "baseType",
      label: "baseType",
      placeholder: "",
      show: false,
      multiple: true,
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      value: "value",
      text: "name",
      type: "select",
      items: [],
    },
    {
      id: "teacherType",
      label: "teacherType",
      placeholder: "",
      show: false,
      multiple: false,
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      value: "value",
      text: "text",
      type: "select",
      items: [
        { text: _t("attribute.fullTime"), value: "FullTime" },
        { text: _t("attribute.partTime"), value: "PartTime" },
      ],
    },
    {
      id: "permissions",
      label: "additionalPermissions",
      placeholder: "",
      show: false,
      summery: true,
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "select",
      value: "value",
      multiple: true,
      items: [],
    },
    {
      id: "fullName",
      label: "fullName",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "nickName",
      label: "nickName",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "userName",
      label: "userName",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "password",
      label: "password",
      placeholder: "",
      disabled: false,
      hint: null,
      showPassword: false,
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "password",
    },
    {
      id: "phoneNumber",
      label: "phoneNumber",
      placeholder: "",
      disabled: false,
      hint: null,
      showPassword: false,
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "emailAddress",
      label: "emailAddress",
      placeholder: "",
      disabled: false,
      hint: null,
      showPassword: false,
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "roleDescription",
      label: "description",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    role: null,
    baseType: [],
    fullName: null,
    nickName: null,
    userName: null,
    permissions: [],
    password: null,
    roleDescription: null,
    phoneNumber: null,
    emailAddress: null,
    teacherType: null,
  },

  /* VALIDATION SCHEMA */
  validations: {},
};
