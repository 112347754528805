<template>
  <v-dialog v-model="isShowVerifyModal" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-on="on" v-bind="attrs">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card>
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">
            {{ $_t("attribute.files") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancel">
            <v-icon color="gray">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <!--      courseBooksLinks    -->
          <v-row>
            <v-col cols="12">
              <h2>
                {{ $_t("attribute.uploadedBooks") }}
              </h2>
            </v-col>
            <template v-if="course.courseBooksLinks">
              <v-col
                cols="12"
                v-for="file in course.courseBooksLinks"
                :key="file"
                class="d-flex align-center"
              >
                <v-btn
                  @click="fileDownloadUrlDynamic(file, 'getCourseFile')"
                  color="primary"
                  class="mr-4"
                >
                  <v-icon>mdi-file-document</v-icon>
                </v-btn>
                <span>{{ file.split("/")[7].split(".")[0] }}</span>
                <v-spacer />
                <span>
                  <v-btn tile @click="deleteFile(file, 'book')" fab text
                    ><v-icon color="red">mdi-delete</v-icon></v-btn
                  >
                </span>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12"> {{ $_t("attribute.booksEmpty") }} </v-col>
            </template>
          </v-row>
          <!--      courseMaterialsLinks    -->
          <v-row>
            <v-col cols="12">
              <h2>
                {{ $_t("attribute.uploadedMaterials") }}
              </h2>
            </v-col>
            <template v-if="course.courseMaterialsLinks">
              <v-col
                cols="12"
                v-for="file in course.courseMaterialsLinks"
                :key="file"
                class="d-flex align-center"
              >
                <v-btn
                  @click="fileDownloadUrlDynamic(file, 'getCourseFile')"
                  color="primary"
                  class="mr-4"
                >
                  <v-icon>mdi-file-document</v-icon>
                </v-btn>
                <span>{{ file.split("/")[7].split(".")[0] }}</span>
                <v-spacer />
                <span>
                  <v-btn tile @click="deleteFile(file)" fab text
                    ><v-icon color="red">mdi-delete</v-icon></v-btn
                  >
                </span>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12"> {{ $_t("attribute.materialEmpty") }} </v-col>
            </template>
          </v-row>
          <hr class="my-4" v-if="!readonly" />
          <v-row v-if="!readonly">
            <v-col cols="12">
              <v-file-input
                truncate-length="15"
                multiple
                accept=".zip,.rar,.7zip"
                v-model="bookFiles"
                outlined
                persistent-hint
                :hint="$_t('attribute.zipHint')"
                :label="$_t('attribute.uploadBooks')"
              ></v-file-input>
              <v-progress-linear
                v-if="bookProgress > 0"
                v-model="bookProgress"
                color="primary"
              ></v-progress-linear>
            </v-col>
            <v-col cols="12">
              <v-file-input
                truncate-length="15"
                multiple
                accept=".zip,.rar,.7zip"
                v-model="materialFiles"
                outlined
                persistent-hint
                :hint="$_t('attribute.zipHint')"
                :label="$_t('attribute.uploadMaterial')"
              ></v-file-input>
              <v-progress-linear
                v-if="materialProgress > 0"
                v-model="materialProgress"
                color="primary"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions v-if="!readonly">
          <v-spacer></v-spacer>
          <v-btn text @click="cancel"> {{ $_t("attribute.cancel") }} </v-btn>
          <v-btn color="green darken-1" dark @click="confirm">
            {{ $_t("attribute.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import message from "@/tools/Message";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import { fileDownloadUrlDynamic } from "@/tools/FileManager";

export default {
  name: "CourseFiles",
  props: {
    course: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowVerifyModal: false,
      bookFiles: [],
      materialFiles: [],
      materialProgress: 0,
      bookProgress: 0,
    };
  },
  computed: {
    lessonInfo() {
      return this.course.lessons.map((item) => {
        return {
          lessonId: item.lessonId,
          teachingTimeInHour: item.teachingTimeInHour,
        };
      });
    },
  },
  methods: {
    fileDownloadUrlDynamic,
    cancel() {
      this.materialFiles = [];
      this.bookFiles = [];
      this.isShowVerifyModal = false;
    },
    async confirm() {
      console.log(this.course);
      if (!this.bookFiles.length && !this.materialFiles.length)
        return message.error(this.$_t("FILE_EMPTY"));

      try {
        let res = null;
        let booksURLs = [];
        let materialURLs = [];

        if (this.bookFiles.length) {
          let booksNames = [];
          const booksFormData = new FormData();
          this.bookFiles.forEach((file) => {
            booksNames.push(file.name);
            booksFormData.append("courseFiles", file);
          });
          const { data } = await api.post(
            urls.qs("uploadCourseFiles", { names: booksNames }),
            booksFormData,
            {
              onUploadProgress: (progressEvent) => {
                this.bookProgress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          );
          booksURLs = data;
        }
        if (this.materialFiles.length) {
          let materialNames = [];
          const materialFormData = new FormData();
          this.materialFiles.forEach((file) => {
            materialNames.push(file.name);
            materialFormData.append("courseFiles", file);
          });
          const { data } = await api.post(
            urls.qs("uploadCourseFiles", { names: materialNames }),
            materialFormData,
            {
              onUploadProgress: (progressEvent) => {
                this.materialProgress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          );
          materialURLs = data;
        }
        res = await this.$actions.editSpecification({
          specificationId: this.course.specificationId,
          title: this.course.title,
          level: this.course.level,
          downloadLink: this.course.downloadLink || null,
          baseType: this.course.baseType,
          lessonInfo: this.lessonInfo,
          courseMaterialsLinks: this.course.courseMaterialsLinks
            ? [...this.course.courseMaterialsLinks, ...materialURLs]
            : materialURLs,
          courseBooksLinks: this.course.courseBooksLinks
            ? [...this.course.courseBooksLinks, ...booksURLs]
            : booksURLs,
        });
        this.materialProgress = 0;
        this.bookProgress = 0;
        if (res) {
          this.cancel();
          return this.$emit("update");
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteFile(file, type) {
      const res = await this.$actions.deleteCourseFile(file.split("/")[7]);
      if (type === "book") {
        if (res) {
          await this.$actions.editSpecification({
            specificationId: this.course.specificationId,
            title: this.course.title,
            level: this.course.level,
            downloadLink: this.course.downloadLink || null,
            baseType: this.course.baseType,
            lessonInfo: this.lessonInfo,
            courseMaterialsLinks: this.course.courseMaterialsLinks,
            courseBooksLinks: this.course.courseBooksLinks.filter(
              (f) => f !== file
            ),
          });
          if (res) return this.$emit("update");
        }
      } else {
        if (res) {
          await this.$actions.editSpecification({
            specificationId: this.course.specificationId,
            title: this.course.title,
            level: this.course.level,
            downloadLink: this.course.downloadLink || null,
            baseType: this.course.baseType,
            lessonInfo: this.lessonInfo,
            courseMaterialsLinks: this.course.courseMaterialsLinks.filter(
              (f) => f !== file
            ),
            courseBooksLinks: this.course.courseBooksLinks,
          });
          if (res) return this.$emit("update");
        }
      }
      this.cancel();
    },
  },
};
</script>

<style scoped></style>
