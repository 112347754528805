<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :title="$_t('admin.components.user_table.users')" />
      </v-col>
      <v-spacer />
      <v-col
        v-if="['Admin', 'TeamLeader', 'AcademicConsultant'].includes(authRole)"
        cols="6"
        sm="4"
        md="2"
      >
        <v-btn
          width="11rem"
          height="2.5rem"
          color="primary"
          dark
          @click="showUserModal"
          class="float-end"
        >
          <v-icon class="mr-3">mdi-account-plus-outline</v-icon>
          {{ $_t("admin.components.admin_actions.add_user") }}
        </v-btn>
        <!--        modal -->
        <v-dialog
          width="700px"
          v-model="isShowUserModal"
          transition="dialog-bottom-transition"
        >
          <v-card class="px-5">
            <v-toolbar class="elevation-0" color="transparent">
              <span class="text-h5 font-weight-bold">{{
                user
                  ? $_t("admin.components.user_table.edit_user")
                  : $_t("admin.components.user_table.add_user")
              }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="px-4">
              <div class="text--disabled">
                {{ $_t("attribute.fill_inputs_create_user") }}
              </div>
            </v-card-text>

            <MainSoftForm>
              <template v-slot:body>
                <MyFormGenerator
                  @clickAppend="showPassword"
                  lg="10"
                  :schema="schema"
                  :validator="$v"
                  :enter="submit"
                ></MyFormGenerator>
              </template>
              <template v-slot:action>
                <v-col cols="12">
                  <div class="d-flex justify-end">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">
                      {{ $_t("attribute.cancel") }}
                    </v-btn>

                    <v-btn
                      color="primary"
                      class="ml-4"
                      @click="submit"
                      :loading="is_loading"
                      :disabled="is_loading || $v.$error"
                    >
                      {{
                        user
                          ? $_t("attribute.edit_user")
                          : $_t("attribute.create_user")
                      }}
                    </v-btn>
                  </div>
                </v-col>
              </template>
              <!--End   form actions ------------------------------------------------------------------>
            </MainSoftForm>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          flat
          outlined
          class="elevation-0"
          dense
          :label="$_t('attribute.role')"
          background-color="inputColor"
          :items="filters"
          @change="initUsers({})"
          v-model="userType"
          item-text="text"
          item-value="value"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="
            pageInfo.page = 1;
            initUsers({ search: $event });
          "
          :headers="headers"
          :items="users"
          :page-info="pageInfo"
          @detail="showDetail"
          :loading="is_loading"
          hide-delete
        >
          <template v-slot:top-right>
            <template v-for="(role, key) in role">
              <v-chip
                class="ma-2 float-end"
                v-if="role !== 0"
                :key="key"
                color="primary"
                label
                outlined
              >
                {{ `${$_t(`attribute.${key}`)}: ${role}` }}
              </v-chip>
            </template>
          </template>
          <template v-slot:fullName="{ item }">
            <div class="d-flex justify-start align-center">
              <v-avatar size="35" class="mr-2" color="primary">
                <span class="white--text text-capitalize">
                  {{ Array.from(item.fullName)[0] }}
                </span>
              </v-avatar>
              <span>{{ item.fullName }}</span>
            </div>
          </template>
          <template v-slot:permissions="{ item }">
            <span
              v-for="text in item.permissions"
              :key="text"
              :style="`color:${generateColor(text)}`"
              >{{ text }}</span
            >
          </template>
          <template v-slot:role="{ item }">
            <span :style="`color:${generateColor(item.role)}`">{{
              `${$_t(`attribute.${item.role}`)} ${
                item.teacherType
                  ? `(${$_t(`attribute.${item.teacherType}`)})`
                  : ""
              }`
            }}</span>
          </template>
          <template v-slot:actions> </template>
          <template v-slot:archive="{ item }">
            <template v-if="['Teacher', 'Student'].includes(item.role)">
              <v-btn
                v-if="
                  ['Admin', 'TeamLeader', 'AcademicConsultant'].includes(
                    authRole
                  )
                "
                text
                :color="item.state === 'Archived' ? 'green' : 'red'"
                :disabled="is_loading"
                class="ml-3"
                @click="archiveUser(item)"
              >
                {{
                  item.state === "Archived"
                    ? $_t("attribute.restore")
                    : $_t("attribute.Archive")
                }}
              </v-btn>
            </template>
          </template>
          <template v-slot:state="{ item }">
            <v-chip dark :color="item.state === 'Archived' ? 'red' : 'green'">{{
              item.state
            }}</v-chip>
          </template>
          <template v-slot:baseType="{ item }">
            <span v-if="['Admin', 'Teacher', 'TeamLeader'].includes(item.role)">
              <template v-if="item.baseType">
                <span v-for="(type, index) in item.baseType" :key="type">
                  {{ $_t(`attribute.${type}`) }}
                  {{ item.baseType[index + 1] ? ", " : "" }}
                </span>
              </template>
            </span>
          </template>
          <template v-slot:task="{ item }">
            <!--            progress stage -->
            <span
              v-if="
                [
                  'Admin',
                  'TeamLeader',
                  'AcademicConsultant',
                  'Teacher',
                  'AssistantTeacher',
                ].includes(authRole)
              "
            >
              <span v-if="['Student'].includes(item.role)">
                <v-tooltip color="primary" bottom>
                  <template v-slot:activator="{ attrs, on: tooltip }">
                    <v-btn
                      text
                      color="primary"
                      @click="toProgress(item)"
                      :disabled="is_loading"
                      class="mr-3"
                      v-bind="attrs"
                      v-on="{ ...tooltip }"
                    >
                      <v-icon>mdi-circle-slice-5</v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $_t("attribute.progressStage") }}</span>
                </v-tooltip>
              </span>
            </span>
            <!--         report   -->
            <span
              v-if="
                [
                  'Admin',
                  'Teacher',
                  'TeamLeader',
                  'AcademicConsultant',
                  'AssistantTeacher',
                ].includes(authRole)
              "
            >
              <span
                v-if="['Student', 'Teacher', 'TeamLeader'].includes(item.role)"
              >
                <v-tooltip color="primary" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $router.push({
                          name: 'admin.users.report',
                          params: { key: item.publicKey },
                        })
                      "
                      :disabled="is_loading"
                      class="mr-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-chart-bar</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $_t("attribute.reports") }}</span>
                </v-tooltip>
              </span>
            </span>
          </template>
          <template v-slot:pagination>
            <v-pagination
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="initUsers"
            ></v-pagination>
          </template>
        </MainTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import { reactive, ref } from "vue";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { USER_TABLE_SCHEMA } from "@/packages/admin/schema/USER_TABLE_HEADER_SCHEMA";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { ADD_USER_SCHEMA } from "@/packages/admin/schema/form/ADD_USER_SCHEMA";
import { minLength, required, email } from "vuelidate/lib/validators";
import { _t } from "@/tools/Utils";
import { admin_types } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import {
  academic_roles,
  roles,
  teamLeader_roles,
} from "@/packages/admin/schema/ROLES";

export default {
  name: "UsersTable",
  components: {
    MyFormGenerator,
    MainSoftForm,
    MainTable,
    TopTitle,
  },
  computed: {
    ...mapGetters({
      authRole: "authentication/role",
      users: "admin/get_users",
      is_loading: "loading/is_loading",
    }),
  },
  data() {
    return {
      schema: ADD_USER_SCHEMA.schema,
      form: ADD_USER_SCHEMA.model,
      user: null,
      search: "",
      isShowTaskModal: false,
      isUserEditable: true,
      progressMenu: false,
      role: {
        Admin: 0,
        Teacher: 0,
        Student: 0,
        FinancialAdmin: 0,
        SchedulingAdmin: 0,
        TeamLeader: 0,
        AcademicConsultant: 0,
      },
    };
  },
  validations() {
    let form = {
      role: { required },
      baseType: {},
      fullName: { required },
      nickName: { required },
      userName: { required },
      permissions: {},
      roleDescription: {},
      password: { required, minLength: minLength(8) },
      phoneNumber: {},
      emailAddress: { email },
      teacherType: {},
    };
    if (
      [
        "Student",
        "AcademicConsultant",
        "FinancialAdmin",
        "SchedulingAdmin",
      ].includes(this.form.role)
    ) {
      form.baseType = {};
    } else {
      form.baseType = { required };
    }
    if (!this.user) {
      form.password = {
        required,
        minLength: minLength(8),
      };
    } else {
      form.password = {
        minLength: minLength(8),
      };
    }
    if (this.form.role === "Teacher") {
      form.teacherType = { required };
    } else {
      form.teacherType = {};
    }
    return {
      form: { ...form },
    };
  },
  watch: {
    async isShowUserModal(newVal) {
      if (!newVal) this.clear();
      this.schema[this.getIdWithName(this.schema, "permissions")].show = false;
      if (this.authRole === "Admin") {
        this.schema[this.getIdWithName(this.schema, "permissions")].show = true;
        this.schema[this.getIdWithName(this.schema, "role")].items = roles;
      } else if (this.authRole === "TeamLeader") {
        this.schema[this.getIdWithName(this.schema, "role")].items =
          teamLeader_roles;
      } else if (this.authRole === "AcademicConsultant") {
        this.schema[this.getIdWithName(this.schema, "role")].items =
          academic_roles;
      }
    },
    user(newVal) {
      if (newVal) {
        this.schema[this.getIdWithName(this.schema, "password")].hint = _t(
          "CHANGE_PASSWORD_HINT"
        );
      } else {
        this.schema[this.getIdWithName(this.schema, "password")].hint = null;
      }
    },
    "form.role": async function (value) {
      this.schema[this.getIdWithName(this.schema, "baseType")].show = true;
      this.schema[this.getIdWithName(this.schema, "baseType")].items =
        admin_types;
      if (this.user?.role === value) return;
      if (value) {
        await this.initPermission(value);
      }
      //role === teacher select teacher type
      if (value === "Teacher") {
        this.schema[this.getIdWithName(this.schema, "teacherType")].show = true;
      } else {
        this.schema[
          this.getIdWithName(this.schema, "teacherType")
        ].show = false;
        this.form.teacherType = null;
      }
      // base type
      // hide base type when role is AcademicConsultant or FinancialAdmin or SchedulingAdmin
      if (
        [
          "Student",
          "AcademicConsultant",
          "FinancialAdmin",
          "SchedulingAdmin",
        ].includes(value)
      ) {
        this.schema[this.getIdWithName(this.schema, "baseType")].show = false;
        // if (value === "Student")
        this.form.baseType = ["any"];
        // else this.form.baseType = [];
      } else {
        this.schema[this.getIdWithName(this.schema, "baseType")].show = true;
        this.form.baseType = [];
      }
    },
  },
  setup(props) {
    const userType = ref(null);
    const isShowUserModal = ref(false);
    const headers = USER_TABLE_SCHEMA.headers;
    const filters = USER_TABLE_SCHEMA.filters;
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: props.itemsPerPage || 10,
    });
    return {
      userType,
      headers,
      pageInfo,
      filters,
      isShowUserModal,
    };
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      let res = null;

      if (this.user) {
        //edit
        if (this.form.password) {
          await this.$actions.resetPassword({
            publicKey: this.user.publicKey,
            password: this.form.password,
          });
        }
        res = await this.$actions.editUser({
          ...this.form,
          publicKey: this.user.publicKey,
          state: this.user.state,
        });
      } else {
        //register
        res = await this.$actions.register({
          ...this.form,
          clientId: process.env.VUE_APP_CLIENT_ID,
          clientSecret: process.env.VUE_APP_CLIENT_SECRET,
          captchaCode: process.env.VUE_APP_CAPTCHA_CODE,
        });
      }
      if (res) {
        await this.initUsers({});
        await this.initAll();
      }
      this.close();
    },
    showUserModal() {
      // const { data } = await this.$actions.getPermissionsPerRole(
      //   this.user.role
      // );
      // this.schema[this.getIdWithName(this.schema, "permissions")].items = data;
      this.isShowUserModal = true;
    },
    async initPermission(value) {
      const { data } = await this.$actions.getPermissionsPerRole(value);
      this.schema[this.getIdWithName(this.schema, "permissions")].items = data;
    },
    close() {
      this.isShowUserModal = false;
      this.isUserEditable = true;
      this.clear();
    },
    clear() {
      this.user = null;
      this.form = {
        role: null,
        fullName: null,
        nickName: null,
        userName: null,
        permissions: [],
        password: null,
        roleDescription: null,
        baseType: null,
        phoneNumber: null,
        emailAddress: null,
        teacherType: null,
      };
      this.$v.$reset();
    },
    async initUsers({ search = this.search }) {
      this.search = search;
      const { pageCount, users } = await this.$actions.getUsers({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        role:
          this.authRole === "AcademicConsultant" ? "Student" : this.userType,
        search: this.search,
        baseType: this.baseType,
      });
      await this.$store.dispatch("admin/setUsers", users);
      this.pageInfo.pageCount = pageCount;
    },
    async initAll() {
      this.role = await this.$actions.getCountOfEachRole();
    },
    toProgress(item) {
      this.$router.push({
        name: "admin.users.progress",
        params: { id: item.publicKey },
      });
    },
    async showDetail(item) {
      this.user = item;
      this.form.fullName = item.fullName;
      this.form.nickName = item.nickName;
      this.form.role = item.role;
      this.form.roleDescription = item.roleDescription;
      this.form.userName = item.userName;
      this.form.permissions = item.permissions;
      this.form.baseType = item.baseType;
      this.form.phoneNumber = item?.phoneNumber || null;
      this.form.emailAddress = item?.emailAddress || null;
      await this.initPermission(item.role);
      if (item.role === "Teacher") {
        this.schema[this.getIdWithName(this.schema, "teacherType")].show = true;
        this.form.teacherType = item.teacherType || null;
      } else {
        this.schema[
          this.getIdWithName(this.schema, "teacherType")
        ].show = false;
        this.form.teacherType = null;
      }

      this.showUserModal();
    },
    // async deleteItem(item) {
    //   await this.$actions.deleteUser(item.publicKey);
    //   await this.initUsers({});
    // },
    generateColor(text) {
      if (text === "Student") return "#AE2CA1";
      if (text === "Admin") return "#2C1AFF";
      if (text === "Teacher") return "#38BF76";
      else return "#C62828";
    },
    showPassword() {
      this.schema[this.getIdWithName(this.schema, "password")].showPassword =
        !this.schema[this.getIdWithName(this.schema, "password")].showPassword;
    },
    async archiveUser(item) {
      const res = await this.$actions.archiveUser({
        userPublicKey: item.publicKey,
        shouldArchive: item.state !== "Archived",
      });
      if (res) await this.initUsers({});
    },
  },
  created() {
    this.initUsers({});
    this.initAll();
  },
};
</script>

<style scoped></style>
