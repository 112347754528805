ّ
<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :title="$_t('admin.components.lessons_table.title')" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="6"
        sm="4"
        md="2"
        v-if="['Admin', 'TeamLeader', 'AcademicConsultant'].includes(authRole)"
      >
        <v-btn
          width="11rem"
          height="2.5rem"
          color="primary"
          dark
          @click="showLessonModal"
          class="float-end"
        >
          <v-icon class="mr-3">mdi-book-open-page-variant-outline</v-icon>
          {{ $_t("admin.components.admin_actions.add_lesson") }}
        </v-btn>
        <!--        modal -->
        <v-dialog
          width="700px"
          v-model="isShowLessonModal"
          transition="dialog-bottom-transition"
        >
          <v-card class="px-4">
            <v-toolbar class="elevation-0" color="transparent">
              <span class="text-h5 font-weight-bold">{{
                lesson
                  ? $_t("admin.components.lessons_table.edit_lesson")
                  : $_t("admin.components.lessons_table.add_lesson")
              }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="px-4">
              <div class="text--disabled">
                Please fill inputs and create your lesson
              </div>
            </v-card-text>
            <MainSoftForm>
              <template v-slot:body>
                <MyFormGenerator
                  lg="10"
                  :schema="schema"
                  :validator="$v"
                  :enter="submit"
                ></MyFormGenerator>
              </template>
              <template v-slot:action>
                <v-col cols="12">
                  <div class="d-flex justify-end">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">
                      {{ $_t("attribute.cancel") }}
                    </v-btn>

                    <v-btn
                      color="primary"
                      class="ml-4"
                      @click="submit"
                      :loading="is_loading"
                      :disabled="is_loading || $v.$error"
                    >
                      {{
                        lesson
                          ? $_t("admin.components.lessons_table.edit_lesson")
                          : $_t("admin.components.lessons_table.add_lesson") +
                            " +"
                      }}
                    </v-btn>
                  </div>
                </v-col>
              </template>
            </MainSoftForm>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          flat
          outlined
          class="elevation-0"
          dense
          background-color="inputColor"
          :label="$_t('attribute.level')"
          :items="filters"
          @change="initLessons({})"
          v-model="lessonType"
          item-text="text"
          item-value="value"
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          flat
          outlined
          :label="$_t('attribute.baseType')"
          class="elevation-0"
          dense
          background-color="inputColor"
          :items="baseTypeFilter"
          @change="initLessons({})"
          v-model="baseType"
          item-text="name"
          item-value="value"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="
            pageInfo.page = 1;
            initLessons({ search: $event });
          "
          :headers="headers"
          :items="lessons"
          :page-info="pageInfo"
          @detail="showDetail"
          @delete="deleteItem"
          :loading="is_loading"
        >
          <template v-slot:actions></template>
          <template v-slot:baseType="{ item }">
            {{ $_t(`attribute.${item.baseType}`) }}
          </template>
          <template v-slot:level="{ item }">
            {{ $_t(`attribute.${item.level}`) }}
          </template>
          <template v-slot:description="{ item }">
            <ShowLessonDescription :description="item.description || ''" />
          </template>
          <template v-slot:pagination>
            <v-pagination
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="initLessons"
            ></v-pagination>
          </template>
        </MainTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { reactive, ref } from "vue";
import { LESSONS_TABLE_SCHEMA } from "@/packages/admin/schema/LESSON_TABLE_SCHEMA";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { ADD_LESSON_SCHEMA } from "@/packages/admin/schema/form/ADD_LESSON_SCHEMA";
import { lessons_filter } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import ShowLessonDescription from "@/packages/admin/components/lessons/ShowLessonDescription";
export default {
  name: "LessonsTable",
  components: {
    ShowLessonDescription,
    MyFormGenerator,
    MainSoftForm,
    MainTable,
    TopTitle,
  },
  computed: {
    ...mapGetters({
      lessons: "admin/get_lessons",
      is_loading: "loading/is_loading",
      authRole: "authentication/role",
    }),
  },
  data() {
    return {
      schema: ADD_LESSON_SCHEMA.schema,
      form: ADD_LESSON_SCHEMA.model,
      lesson: null,
      search: "",
    };
  },
  validations: {
    ...ADD_LESSON_SCHEMA.validations,
  },
  setup(props) {
    const lessonType = ref(null);
    const isShowLessonModal = ref(false);
    const baseType = ref(null);
    const baseTypeFilter = lessons_filter;
    const headers = LESSONS_TABLE_SCHEMA.headers;
    const filters = LESSONS_TABLE_SCHEMA.filters;
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: props.itemsPerPage || 10,
    });
    return {
      lessonType,
      baseType,
      baseTypeFilter,
      headers,
      pageInfo,
      filters,
      isShowLessonModal,
    };
  },
  watch: {
    isShowLessonModal(newVal) {
      if (!newVal) this.clear();
    },
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      try {
        let res = null;
        if (this.lesson) {
          res = await this.$actions.editLesson({
            ...this.form,
            lessonId: this.lesson.lessonId,
            teachingTimeInHour: parseFloat(this.form.teachingTimeInHour),
            level: this.form.level.value,
          });
        } else {
          res = await this.$actions.createLesson({
            ...this.form,
            teachingTimeInHour: parseFloat(this.form.teachingTimeInHour),
            level: this.form.level.value,
          });
        }

        if (res) {
          await this.initLessons({});
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async showLessonModal() {
      // await this.$actions.getTitles();
      // await this.$actions.getNames();
      this.isShowLessonModal = true;
    },
    close() {
      this.isShowLessonModal = false;
      this.clear();
    },
    clear() {
      this.lesson = null;
      this.form = {
        name: null,
        title: null,
        subtitle: null,
        level: null,
        teachingTimeInHour: null,
        description: null,
        baseType: null,
      };
      this.$v.$reset();
    },
    async initLessons({ search = this.search }) {
      this.search = search;
      const { pageCount, lessons } = await this.$actions.getLessons({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        level: this.lessonType,
        search: this.search,
        baseType: this.baseType,
      });
      await this.$store.dispatch("admin/setLessons", lessons);
      this.pageInfo.pageCount = pageCount;
    },
    showDetail(item) {
      this.lesson = item;
      this.form.level = { text: item.level, value: item.level };
      this.form.name = item.name;
      this.form.title = item.title;
      this.form.subtitle = item.subTitle;
      this.form.description = item.description;
      this.form.baseType = item.baseType;
      this.form.teachingTimeInHour = item.teachingTimeInHour.toString();
      this.showLessonModal();
    },
    async deleteItem(item) {
      await this.$actions.deleteLesson(item.lessonId);
      await this.initLessons({});
    },
  },
  created() {
    this.initLessons({});
  },
};
</script>

<style scoped></style>
