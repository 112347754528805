import { _t } from "@/tools/Utils";
import { levels_filter } from "@/packages/admin/schema/LEVELS";

export const LESSONS_TABLE_SCHEMA = {
  headers: [
    {
      text: _t("admin.components.lessons_table.header.name"),
      align: "start",
      sortable: false,
      value: "name",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("admin.components.lessons_table.header.title"),
      sortable: false,
      value: "title",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("admin.components.lessons_table.header.subTitle"),
      sortable: false,
      value: "subTitle",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("admin.components.lessons_table.header.level"),
      sortable: false,
      value: "level",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("admin.components.lessons_table.header.teachingTimeInHour"),
      sortable: false,
      value: "teachingTimeInHour",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.baseType"),
      sortable: false,
      value: "baseType",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("admin.components.lessons_table.header.description"),
      sortable: false,
      value: "description",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: "",
      sortable: false,
      value: "actions",
      class: "text--disabled text-subtitle-1",
      width: "200px",
    },
  ],
  filters: [...levels_filter],
};
